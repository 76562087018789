'use client'

import { useState } from 'react'
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { toast } from 'sonner'
// import { toast } from "@/components/ui/use-toast"

export default function QRCodeButton() {
  const [open, setOpen] = useState(false)
  const qqGroupNumber = '660745844' // 替换为实际的QQ群号

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(qqGroupNumber)
      toast.success( '复制成功')
    } catch (err) {
      toast.error('无法复制QQ群号，请手动复制')
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="primary" size={'sm'}>QQ群</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>扫描二维码加入QQ群</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center space-y-4">
          <img
            src="/images/qq-qrcode.jpg"
            alt="QQ群二维码"
            className="w-48 h-48 rounded-sm shadow-sm"
          />
          <div className="flex items-center space-y-2 flex-col">
            <span className="text-sm text-muted-foreground">群号：{qqGroupNumber}</span>
            <Button variant="blue" size="sm" onClick={copyToClipboard}>
              复制群号
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

